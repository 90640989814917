@font-face {
  font-family: "BogartLight";
  src: local("Bogart-Light"),
    url("./library/fonts/Bogart-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Bogart";
  src: local("Bogart-Regular"),
    url("./library/fonts/Bogart-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "BogartMedium";
  src: local("Bogart-Medium"),
    url("./library/fonts/Bogart-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("HankenGrotesk-VariableFont_wght"),
    url("./library/fonts/HankenGrotesk/HankenGrotesk-VariableFont_wght.ttf")
      format("truetype");
  font-display: swap;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  margin: 0;
  font-family: "Bogart", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f2f1;
}

span {
  font-family: "Hanken Grotesk";
}
